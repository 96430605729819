import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AnyAction,
  EntityState,
  ThunkDispatch,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  axiosBaseQuery,
  BaseQueryError,
  createAxiosInstance,
  errorHandlingInterceptor,
  isBaseQueryError
} from '@client';
import { BankAccountResponse } from '@module/bank-account/model/BankAccountResponse';
import { RootState } from '../../store';
import VerbalVerifyBankAccountDTO from '@state/types/VerbalVerifyBankAccountDTO';
import {
  disconnectSocket,
  subscribeToTopic
} from '../../../../common/utils/setup-sse';

export const bankAccountsAdapter = createEntityAdapter<BankAccountResponse>({
  selectId: (b) => b.id
});

const updateFetchAgentBankAccountCache = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  data: BankAccountResponse
) => {
  dispatch(
    agentBankAccountsApi.util.upsertQueryData(
      'fetchAgentBankAccount',
      { bankAccountId: data.id },
      data
    )
  );
};

export const agentBankAccountsApi = createApi({
  baseQuery: axiosBaseQuery({
    customAxiosInstance: createAxiosInstance({
      errorInterceptor: errorHandlingInterceptor({
        handleUnauthorizedResponse: false
      })
    })
  }),
  tagTypes: ['AgentBankAccounts'],
  reducerPath: 'agent-bank-accounts_api',
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    fetchAgentBankAccounts: build.query<
      {
        currentState: EntityState<BankAccountResponse>;
        nextState: EntityState<BankAccountResponse>;
        worthUpdate?: boolean;
      },
      { currency: string }
    >({
      query: ({ currency }) => ({
        url: `/secured/supplier-bank-accounts`,
        method: 'GET',
        params: {
          currency
        }
      }),
      transformResponse(response: BankAccountResponse[]) {
        const newState = bankAccountsAdapter.addMany(
          bankAccountsAdapter.getInitialState(),
          response
        );
        return {
          currentState: newState,
          nextState: newState,
          worthUpdate: false
        };
      },
      transformErrorResponse: (error) => {
        if ((error as BaseQueryError)?.status === 403) {
          document.location.href = '/error-no-access';
        }
      },
      async onCacheEntryAdded(
        { currency },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }
      ) {
        const state = getState() as RootState;
        const {
          session: {
            authentication: { profile, token }
          }
        } = state;

        const profileIid = profile?.id;

        if (profileIid && token) {
          try {
            // wait for the initial query to resolve before proceeding
            await cacheDataLoaded;

            const handleNotification = (event: any) => {
              const { data } = event;
              if (data) {
                const { action, bankAccountDTO } = JSON.parse(data) as {
                  action: 'CREATED' | 'UPDATED' | 'DELETED';
                  bankAccountDTO: BankAccountResponse;
                  profileId: number;
                };

                updateCachedData((draft) => {
                  const currentBankAccount =
                    draft.currentState.entities[bankAccountDTO.id];

                  const incomingBankAccountHasValidCurrencies =
                    bankAccountDTO.currency === currency ||
                    bankAccountDTO.supportedCurrencies?.includes(currency);

                  const currentBankAccountHasValidCurrencies =
                    currentBankAccount &&
                    (currentBankAccount.currency === currency ||
                      currentBankAccount.supportedCurrencies?.includes(
                        currency
                      ));

                  switch (action) {
                    case 'CREATED':
                      bankAccountsAdapter.addOne(
                        draft.nextState,
                        bankAccountDTO
                      );
                      draft.worthUpdate = incomingBankAccountHasValidCurrencies;
                      break;
                    case 'UPDATED':
                      bankAccountsAdapter.upsertOne(draft.nextState, {
                        ...bankAccountDTO,
                        // force update supportedCurrencies
                        supportedCurrencies:
                          bankAccountDTO.supportedCurrencies ?? []
                      });

                      if (currentBankAccount) {
                        const bankAccountStateChange =
                          currentBankAccount.state !== bankAccountDTO.state;
                        const bankAccountCurrencyValidityChange =
                          currentBankAccountHasValidCurrencies !==
                          incomingBankAccountHasValidCurrencies;
                        draft.worthUpdate =
                          bankAccountStateChange ||
                          bankAccountCurrencyValidityChange;
                      } else {
                        draft.worthUpdate =
                          incomingBankAccountHasValidCurrencies;
                      }

                      break;
                    case 'DELETED':
                      bankAccountsAdapter.removeOne(
                        draft.nextState,
                        bankAccountDTO.id
                      );
                      draft.worthUpdate = currentBankAccountHasValidCurrencies;

                      break;
                    default:
                    // code block
                  }
                });
              }
            };
            subscribeToTopic(
              token,
              profile.id,
              'DA_BANK_ACCOUNTS_UPDATE',
              handleNotification
            );
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          // cacheEntryRemoved will resolve when the cache subscription is no longer active
          await cacheEntryRemoved;
          // perform cleanup steps once the `cacheEntryRemoved` promise resolves
          disconnectSocket();
        }
      }
    }),
    fetchAgentBankAccount: build.query<
      BankAccountResponse,
      { bankAccountId: number }
    >({
      query: ({ bankAccountId }) => ({
        url: `/secured/bank-account/${bankAccountId}/load`,
        method: 'GET'
      }),
      transformErrorResponse: (error) => {
        if ((error as BaseQueryError)?.status === 403) {
          document.location.href = '/error-no-access';
        }
      }
    }),
    updateAgentBankAccount: build.mutation<BankAccountResponse, FormData>({
      query: (data) => ({
        url: '/secured/bank-account/save',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          updateFetchAgentBankAccountCache(dispatch, data);
        } catch {
          //
        }
      },
      transformErrorResponse: (error) => {
        if ((error as BaseQueryError)?.status === 403) {
          document.location.href = '/error-no-access';
        }
      }
    }),
    approveAgentBankAccount: build.mutation<BankAccountResponse, FormData>({
      query: (data) => ({
        url: '/secured/bank-account/approve',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          updateFetchAgentBankAccountCache(dispatch, data);
        } catch {
          //
        }
      },
      transformErrorResponse: (error) => {
        if ((error as BaseQueryError)?.status === 403) {
          document.location.href = '/error-no-access';
        }
      }
    }),
    updateApprovedAgentBankAccount: build.mutation<
      BankAccountResponse,
      FormData
    >({
      query: (data) => ({
        url: '/secured/bank-account/edit-approved',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          updateFetchAgentBankAccountCache(dispatch, data);
        } catch {
          //
        }
      },
      transformErrorResponse: (error) => {
        if ((error as BaseQueryError)?.status === 403) {
          document.location.href = '/error-no-access';
        }
      }
    }),
    verbalVerifyAgentBankAccount: build.mutation<
      BankAccountResponse,
      VerbalVerifyBankAccountDTO
    >({
      query: (data) => ({
        url: '/secured/bank-account/verbal-verify',
        method: 'PATCH',
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          updateFetchAgentBankAccountCache(dispatch, data);
        } catch {
          //
        }
      }
    })
  })
});

export type StateWithAgentBankAccountsApi = {
  [agentBankAccountsApi.reducerPath]: ReturnType<
    typeof agentBankAccountsApi.reducer
  >;
};

export const {
  useLazyFetchAgentBankAccountsQuery,
  useFetchAgentBankAccountQuery,
  useUpdateAgentBankAccountMutation,
  useApproveAgentBankAccountMutation,
  useUpdateApprovedAgentBankAccountMutation,
  useVerbalVerifyAgentBankAccountMutation
} = agentBankAccountsApi;

export const refreshBankAccountsAction = (currency: string) =>
  agentBankAccountsApi.util.updateQueryData(
    'fetchAgentBankAccounts',
    { currency },
    (draft) => {
      draft.currentState = bankAccountsAdapter.setAll(
        draft.currentState,
        bankAccountsAdapter.getSelectors().selectAll(draft.nextState)
      );
      draft.worthUpdate = false;
    }
  );
